import * as React from "react";
import { Divider, Typography } from "antd";

import HeaderComponent from "../../components/Header";

import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import FooterComponent from "../../components/Footer";

import favicon from "../../images/favicon.ico";
import Wrapper from "../../components/Wrapper";
import bannerImage from "../../images/banner-no-text.jpg";
import constructionImage from "../../images/our-business/construction.webp";
import miningImage from "../../images/our-business/mining.webp";
import factoryImage from "../../images/our-business/factory.webp";
import transportationImage from "../../images/our-business/transportation.webp";
import hotelImage from "../../images/our-business/hotel.webp";
import mallImage from "../../images/our-business/mall.webp";
import marineImage from "../../images/our-business/marine.webp";
import farmImage from "../../images/our-business/farm.webp";

import "./our-business.scss";

import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

// markup
const OurBusinessPage = () => {
  const { t } = useTranslation();

  const solarPoints = [
    "details.1.1",
    "details.1.2",
    "details.1.3",
    "details.1.4",
  ];

  const lubricantPoints = [
    "details.2.1",
    "details.2.2",
    "details.2.3",
    "details.2.4",
    "details.2.5",
  ];

  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prabu Cahaya Abadi | Our Business</title>
        <meta
          name="description"
          content="PT. Prabu Cahaya Abadi mendistribusikan HSD / Solar Industri ke berbagai macam pengguna / industri di seluruh wilayah Indonesia."
        />
        <link rel="icon" href={favicon} />
      </Helmet>
      <HeaderComponent />
      <div>
        <img
          src={bannerImage}
          alt="banner"
          style={{ maxHeight: 200, width: "100%", objectFit: "cover" }}
        />
      </div>
      <Wrapper>
        <div className="our-business">
          <Typography className="our-business__title">Our Business</Typography>
          <Divider className="our-business__divider" style={{ marginTop: 0 }} />

          {/* Carousel */}
          <div className="our-business__carousel">
            <Carousel
              // centerMode
              // centerSlidePercentage={80}
              infiniteLoop
              showThumbs={false}
              swipeable
              emulateTouch
              autoPlay
            >
              <div>
                <img
                  src={constructionImage}
                  alt="construction"
                  className="our-business__carousel__image"
                />
                <p className="legend">{t("construction")}</p>
              </div>
              <div>
                <img
                  src={miningImage}
                  alt="mining"
                  className="our-business__carousel__image"
                />
                <p className="legend">{t("mining")}</p>
              </div>
              <div>
                <img
                  src={factoryImage}
                  alt="factory"
                  className="our-business__carousel__image"
                />
                <p className="legend">{t("factory")}</p>
              </div>
              <div>
                <img
                  src={transportationImage}
                  alt="transportation"
                  className="our-business__carousel__image"
                />
                <p className="legend">{t("transportation")}</p>
              </div>
              <div>
                <img
                  src={hotelImage}
                  alt="hotel"
                  className="our-business__carousel__image"
                />
                <p className="legend">{t("hotel")}</p>
              </div>
              <div>
                <img
                  src={mallImage}
                  alt="mall"
                  className="our-business__carousel__image"
                />
                <p className="legend">{t("mall")}</p>
              </div>
              <div>
                <img
                  src={marineImage}
                  alt="mall"
                  className="our-business__carousel__image"
                />
                <p className="legend">{t("marine")}</p>
              </div>
              <div>
                <img
                  src={farmImage}
                  alt="mall"
                  className="our-business__carousel__image"
                />
                <p className="legend">{t("farm")}</p>
              </div>
            </Carousel>
          </div>

          {/* Business 1 */}
          <div className="our-business__content">
            <Typography className="our-business__content__title">
              {t("title.1")}
            </Typography>
            <Typography className="our-business__content__description">
              {t("description.1")}
            </Typography>
          </div>
          <div className="our-business__details">
            <Typography>{t("details.1.title")}</Typography>

            {solarPoints.map((item) => {
              return (
                <div className="our-business__details__points" key={item}>
                  <Typography className="our-business__details__points__title">
                    - {t(`${item}.title`)}
                  </Typography>
                  <Typography className="our-business__details__title">
                    {t(`${item}.description`)}
                  </Typography>
                </div>
              );
            })}
          </div>

          <Divider className="our-business__divider" />

          {/* Business 2 */}
          <div className="our-business__content">
            <Typography className="our-business__content__title">
              {t("title.2")}
            </Typography>
            <Typography className="our-business__content__description">
              {t("description.2")}
            </Typography>
          </div>

          <div className="our-business__details">
            <Typography>{t("details.2.title")}</Typography>

            {lubricantPoints.map((item) => {
              return (
                <div className="our-business__details__points">
                  <Typography className="our-business__details__points__title">
                    - {t(`${item}`)}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </Wrapper>
      <FooterComponent />
    </div>
  );
};

export default OurBusinessPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["our-business", "business"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
